import { Contract } from 'ethers';
import getAddress from './getNftAddress';
import abi from '../abis/NftAbi.json';



export default function getNftContract(chainNumber, library, withSigner) {
  const address = getAddress(chainNumber);
  if (!address) return null;
  if (withSigner)
    return new Contract(address, abi, library.getSigner());
  return new Contract(address, abi, library);
}
