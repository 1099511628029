import { put, select } from 'redux-saga/effects';
import queryString from 'query-string';
import { utils } from 'ethers';
import { nullAddress } from '../../constants';
import * as A from './actions';
import * as S from './selectors';



const logLocation = 'sagas/ref/sagas';

export default ({ api, }) => {

  const clearSaga = function* () {
    yield put(A.clear());
  }

  const updateSaga = function* () {
    try {
      const parsed = queryString.parse(window.location.search);
      const parentAddress = yield select(S.getParentAddress);
      const parentId = yield select(S.getParentId);

      if (parsed.r) {
        if (utils.isAddress(parsed.r)) {
          yield put(A.setParentAddress(parsed.r));
          yield put(A.setParentId(null));
          const res = yield api.getRefId(parsed.r);
          if (res.ok) {
            yield put(A.setParentId(res.result.id));
          }
        } else {
          yield put(A.setParentId(parsed.r));
          yield put(A.setParentAddress(nullAddress));
          const res = yield api.getRefAddress(parsed.r);
          if (res.ok) {
            yield put(A.setParentAddress(res.result.address));
          }
        }
      } else if (parentId && parentAddress === nullAddress) {
        const res = yield api.getRefAddress(parentId);
        if (res.ok) {
          yield put(A.setParentAddress(res.result.address));
        }
      }
    } catch (error) {
      yield console.error(logLocation, 'updateSaga()', error);
    }
  }

  return {
    clearSaga,
    updateSaga,
  };
}
