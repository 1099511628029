import React from "react";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import { FlipTimer } from "../../../components/FlipTimer/FlipTimer";
import Social from "../../../components/Social";
import { presaleStartTime } from "../../../configs";
import "./Explore.css";

export const Explore: React.FC = () => {
  const { t } = useTranslation("explore");

  return (
    <section className="explore">
      <Container>
        <div data-aos="fade-up-left">
          <Header as="h2" content={t("Header")} className="star lined" />
        </div>
        <div className="ready">{t("Ready")}</div>
        {/*<FlipTimer endTime={presaleStartTime} />*/}
        <div className="explore__icons">
          <Social />
        </div>
        <Grid columns="equal" stackable textAlign="left">
          <Grid.Column>
            <Header as="h3">
              <Trans t={t} i18nKey="Beginning">
                1<strong>2</strong>
              </Trans>
            </Header>
            <div className="explore__first">{t("FirstCollection")}</div>
            <div className="view-mobile explore__nft">
              <Trans t={t} i18nKey="NFTs">
                1<b>2</b>
              </Trans>
            </div>
            <div className="view-mobile explore__nft">{t("Symbolize")}</div>
            <div className="view-mobile hints">
              <Image inline src="./images/banknote.png" alt="Banknote" />
              <div className="hint hint-code">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("CODE64")}</div>
                  {t("CODE641")}
                </div>
              </div>
              <div className="hint hint-signature">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("TheSignature")}</div>
                  {t("TheSignature1")}
                </div>
              </div>
              <div className="hint hint-year">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("Year")}</div>
                  {t("Year1")}
                </div>
              </div>
              <div className="hint hint-serial">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("SerialNumber")}</div>
                  {t("SerialNumber1")}
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="hints view-desktop">
              <Image inline src="./images/banknote.png" alt="Banknote" />
              <div className="hint hint-code">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("CODE64")}</div>
                  {t("CODE641")}
                </div>
              </div>
              <div className="hint hint-signature">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("TheSignature")}</div>
                  {t("TheSignature1")}
                </div>
              </div>
              <div className="hint hint-year">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("Year")}</div>
                  {t("Year1")}
                </div>
              </div>
              <div className="hint hint-serial">
                <Image inline src="./icons/point.png" alt="Hint" />
                <div className="hint-body">
                  <div className="hint-name"> {t("SerialNumber")}</div>
                  {t("SerialNumber1")}
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid columns="equal" stackable textAlign="left">
          <Grid.Column className="">
            <div data-aos="fade-right">
              <div className="view-desktop explore__nft">
                <Trans t={t} i18nKey="NFTs">
                  1<b>2</b>
                </Trans>
              </div>
              <div className="view-desktop"> {t("Symbolize")}</div>
              <div className="tab"> {t("Denomination")}</div>
              {t("Denomination1")}
              <div className="tab"> {t("SerialNumber")}</div>
              {t("SerialNumber1")}
            </div>
          </Grid.Column>
          <Grid.Column className="">
            <div data-aos="fade-left" data-aos-offset="500">
              <div className="tab"> {t("CODE64")}</div>
              {t("CODE641")}
              <div className="tab"> {t("TheSignature")}</div>
              {t("TheSignature1")}
              <div className="tab"> {t("Robots")}</div>
              {t("Robots1")}
              <div className="tab"> {t("Year")}</div>
              {t("Year1")}
            </div>
          </Grid.Column>
        </Grid>
        <div className="unique">
          <Trans t={t} i18nKey="Each">
            1<strong>2</strong>
          </Trans>
        </div>
      </Container>
    </section>
  );
};
