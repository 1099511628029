import { Suspense } from "react";
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { ToastContainer } from 'react-toastify';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import { ModalProvider } from 'react-simple-modal-provider';
import { HashRouter } from "react-router-dom";
import configureStore from './store';
import { NetworkContextName } from './constants/index';
import getLibrary from './utils/getLibrary';
import i18n from './i18n';
import MintModal from './modal/MintModal';



const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const { store, persistor } = configureStore(window?.__PRELOADED_STATE__);
delete window?.__PRELOADED_STATE__;

const modals = [MintModal];

function Providers({ children }: any) {
  return (
    <Suspense fallback={null}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <StoreProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <I18nextProvider i18n={i18n}>
                <ModalProvider value={modals}>
                  <HashRouter>
                    {children}
                  </HashRouter>
                </ModalProvider>
              </I18nextProvider>
            </PersistGate>
          </StoreProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>

      <ToastContainer
        position='top-right'
        autoClose={3500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 10000, width: '350px' }}
      />
    </Suspense>
  );
}


export default Providers;