export const isProduction = process.env.NODE_ENV === 'production';


export const companyUrl = "https://robotoken.space/";
export const companySupportEmail = "support@robotoken.space";
export const companyTelegram = "https://t.me/robotokenspace";
export const companyMedium = "https://medium.com/@robotoken.space";
export const companyTwitter = "https://twitter.com/RobotokenSpace";
export const companyInstagram = "https://www.instagram.com/robotokenspace/";
export const companyOpensea = "https://opensea.io/collection/robotoken";
export const companyDiscord = "https://discord.gg/RoboToken";


export const NetworkContextName = 'NETWORK';
export const connectorLocalStorageKey = 'connectorId';
export enum ChainId {
  ETH_MAINNET = 1,
  ETH_ROPSTEN = 3,
  ETH_RINKEBY = 4,
  ETH_GOERLI = 5,
  ETH_KOVAN = 42,

  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
};

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
}

export const nullAddress = '0x0000000000000000000000000000000000000000';
