export default ({
  apiUrl,
  get,
}) => {

  const getRefId = (refAddress) => {
    return get({
      url: apiUrl,
      endPoint: '/v1/ref',
      contentType: 'application/json',
      data: {
        refAddress,
      },
    });
  }

  const getRefAddress = (refId) => {
    return get({
      url: apiUrl,
      endPoint: '/v1/ref',
      contentType: 'application/json',
      data: {
        refId,
      },
    });
  }

  return {
    getRefId,
    getRefAddress,
  };
}
