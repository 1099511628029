import React from "react";
import ym from 'react-yandex-metrika';
import {
  companyDiscord,
  companyInstagram,
  companyOpensea,
  companyTwitter,
} from "../constants";
import getExternalLinkProps from "../utils/getExternalLinkProps";
import OpenSeaIcon from "./Svg/OpenSea";
import DiscordIcon from "./Svg/Discord";
import InstagramIcon from "./Svg/Instagram";
import TwitterIcon from "./Svg/Twitter";

const Social: React.FC = () => {
  const onClickSocial = (name: string) => {
    ym('reachGoal', name);
  }

  return (
    <>
      <a href={companyOpensea} {...getExternalLinkProps()} onClick={() => onClickSocial('OpenOpenSea')}>
        <OpenSeaIcon />
      </a>
      <a href={companyDiscord} {...getExternalLinkProps()} onClick={() => onClickSocial('OpenDiscord')}>
        <DiscordIcon />
      </a>
      <a href={companyInstagram} {...getExternalLinkProps()} onClick={() => onClickSocial('OpenInstagram')}>
        <InstagramIcon />
      </a>
      <a href={companyTwitter} {...getExternalLinkProps()} onClick={() => onClickSocial('OpenTwitter')}>
        <TwitterIcon />
      </a>
    </>
  );
};

export default Social;
