import React from "react";
import { About } from "./componets/About";
import { Collection } from "./componets/Collection";
import { Explore } from "./componets/Explore";
import { Countdown } from "./componets/Countdown";
import { Robots } from "./componets/Robots";
import { Road } from "./componets/Road";
import { Why } from "./componets/Why";
import { Miles } from "./componets/Miles";

const Main: React.FC = () => {
  return (
    <>
      <Collection />
      <Explore />
      <Why />
      <Robots />
      <About />
      <Countdown />
      <Miles />
      <Road />
    </>
  );
};

export default Main;
