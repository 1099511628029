import { nftMainnetAddress, nftRinkebyAddress } from '../configs';



export default function getNftAddress(chainNumber) {
  if (chainNumber === 1)
    return nftMainnetAddress;
  if (chainNumber === 4)
    return nftRinkebyAddress;
  return null;
}
