import React, { useState, useEffect } from "react";
import { Container, Grid, Header, Button, Image } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import { useModal } from "react-simple-modal-provider";
import ym from 'react-yandex-metrika';
import "./Collection.css";

const now = () => Math.trunc(new Date().getTime() / 1000);

export const Collection: React.FC = () => {
  const { t } = useTranslation("collection");
  const { open: openMintModal } = useModal("MintModal");
  function openMintModal2() {
    window.open('https://discord.gg/RoboToken', '_blank');
    ym('reachGoal', 'OpenDiscord');
  }

  const [imgLink, setImgLink] = useState('/images/image-0.png');
  const [time, setTime] = useState(0);
  useEffect(() => {
    setTime(now());
    const intervalId = setInterval(() => {
      setTime(now());
    }, 3000);
    return () => {
      clearInterval(intervalId);
    }
  }, []);

  /*useEffect(() => {
    if (imgLink === '/images/image-0.png')
      setImgLink('/images/image-2.jpg');
    else if (imgLink === '/images/image-2.jpg')
      setImgLink('/images/image-3.jpg');
    else if (imgLink === '/images/image-3.jpg')
      setImgLink('/images/image-0.png');
  }, [time]);*/

  return (
    <section className="collection">
      <Container>
        <div className="animate">
          <Image fluid src={imgLink} alt="robotoken" />
          {/*<div className="l1" />
          <div className="l2" />
          <div className="l3" />
          <div className="l4" />
          <div className="l5" />
          <div className="l6" />
          <div className="l7" />
          <div className="l8" />
          <div className="l9" />
          <div className="l10" />*/}
        </div>
        <div data-aos="fade-up-right">
          <Header as="h2" content={t("Header")} />
        </div>
        <Grid columns="2" stackable className="robotoken">
          <Grid.Column textAlign="left" width={7}>
            {t("Robotoken")}
          </Grid.Column>
          <Grid.Column width={9} textAlign="right">
            <Button onClick={openMintModal2}>{t("Mint")}</Button>
          </Grid.Column>
        </Grid>
        <Grid columns="2" stackable className="robotoken-2">
          <Grid.Column textAlign="left" width={7}>
            <div data-aos="fade-right">
              <Header as="h3" content={t("WeAre")} className="weAre" />
            </div>
            <div className="robotoken-img">
              <div data-aos="fade-left" data-aos-easing="ease-in-out">
                <Image src="./images/robotoken.png" alt="robotoken" centered />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column className="lines" width={9}>
            <Image src="./icons/star-right.png" className="star-right" />
            <div
              className="line line-1"
              data-aos="fade-left"
              data-aos-offset="100"
            />
            <div
              className="line line-2"
              data-aos="fade-left"
              data-aos-offset="300"
            />
            <div className="change">
              <div data-aos="zoom-in">
                <Trans t={t} i18nKey="Change">
                  1<strong>2</strong>
                </Trans>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-delay="500">
              <div className="owners">{t("Owners")}</div>
              <ul>
                <li>{t("OwnersItem1")}</li>
                <li>{t("OwnersItem2")}</li>
                <li>{t("OwnersItem3")}</li>
              </ul>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
};
