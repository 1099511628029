import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Container, Dropdown, Sidebar, Menu, Image } from "semantic-ui-react";
import Social from "./Social";

export const langOptions = [
  { key: "en", value: "en", text: "En" },
  //{ key: "ru", value: "ru", text: "Ru" },
];

const Header: React.FC = () => {
  const { i18n, t } = useTranslation("layout");

  const [isMenuOpen, setMenuOpen] = useState(false);

  const changeLanguage = (e: any, se: any) => {
    i18n.changeLanguage(se.value);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Container>
        <div className="menu-header space-bt">
          <div className="text-left">
            <div className="musk">“Musk’s Children”</div>
            <div className="community">
              {t("Community")}
              <span className="from">
                <Trans t={t} i18nKey="From">
                  1<b>2</b>
                </Trans>
              </span>
            </div>
          </div>
          <div>
            {/*<Dropdown
              name="lang"
              id="lang"
              options={langOptions}
              value={i18n.language}
              onChange={changeLanguage}
            />*/}
            <div className="menu-elements">
              <Image
                src="/icons/menu.png"
                onClick={toggleMenu}
                className="menuButton"
                inline
              />

              <div className="social">
                <Social />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Sidebar
        as={Menu}
        animation="overlay"
        direction="top"
        onHide={() => setMenuOpen(false)}
        vertical
        visible={isMenuOpen}
      >
        <Social />
      </Sidebar>
    </>
  );
};

export default Header;
