import React from "react";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import "./About.css";

export const About: React.FC = () => {
  const { t } = useTranslation("about");

  return (
    <section className="about">
      <Container>
        <Grid columns="equal" stackable textAlign="left">
          <Grid.Column>
            <div data-aos="zoom-out-up">
              <Header as="h2" content={t("Header")} />
            </div>
            {t("Space")}
            <div className="moving-image">
              <div data-aos="fade-left" data-aos-easing="ease-in-out">
                <Image
                  src="./images/robotoken-about.png"
                  alt="robotoken"
                  centered
                />
              </div>
            </div>
            <Header as="h3">
              <Trans t={t} i18nKey="Beginning">
                1<strong>2</strong>
              </Trans>
            </Header>
            {t("RSpace")}
            <div className="about__list"> {t("Within")}</div>
            <ul>
              <li>{t("Item1")}</li>
              <li>{t("Item2")}</li>
              <li>{t("Item3")}</li>
              <li>{t("Item4")}</li>
              <li>{t("Item5")}</li>
              <li>{t("Item6")}</li>
              <li>{t("Item7")}</li>
              <li>{t("Item8")}</li>
            </ul>
          </Grid.Column>
          <Grid.Column className="about__picture" only="computer">
            <div data-aos="fade-left" data-aos-easing="ease-in-out">
              <Image src="./images/robotoken-about.png" alt="robotoken" />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
};
