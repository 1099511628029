export default function Icon() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="filling" d="M19.8113 24.3852V24.3684C17.8677 24.3684 16.2993 25.9751 16.2993 27.9499C16.2993 29.9247 17.8677 31.5315 19.8113 31.5315C21.7549 31.5315 23.3235 29.9248 23.3235 27.9667C23.3235 25.9917 21.7379 24.3852 19.8113 24.3852Z" fill="black" />
      <path id="filling" d="M26 0.609375C11.9772 0.609375 0.609375 11.9772 0.609375 26C0.609375 40.0228 11.9772 51.3906 26 51.3906C40.0228 51.3906 51.3906 40.0228 51.3906 26C51.3906 11.9772 40.0228 0.609375 26 0.609375ZM35.3939 39.3972C35.3939 39.3972 34.1148 37.9742 33.1946 36.7194C37.6273 35.4642 39.2985 32.9539 39.2985 32.9539C34.6897 35.8034 30.3854 36.8998 26 36.7573C21.6146 36.8995 17.3103 35.8034 12.702 32.9539C12.702 32.9539 14.3727 35.4642 18.8059 36.7194C17.8854 37.9746 16.6067 39.3972 16.6067 39.3972C9.0024 39.2298 6.05312 34.5773 5.8825 34.778C5.8825 24.4187 10.9119 15.9835 10.9119 15.9835C15.4127 12.7538 19.6067 12.6195 20.408 12.6028L20.9195 13.0882C15.174 14.6948 12.3951 17.2052 12.3951 17.2052C17.3263 14.8235 21.974 13.9857 26 13.9355C30.0261 13.9863 34.6734 14.8235 39.6049 17.2052C39.6049 17.2052 36.826 14.6948 31.0806 13.0882L31.592 12.6028C32.3934 12.6195 36.5873 12.7534 41.0881 15.9835C41.0881 15.9835 46.1174 24.4185 46.1174 34.778C45.9469 34.5773 42.9976 39.2298 35.3939 39.3972Z" fill="black" />
      <path id="filling" d="M32.1886 24.3682V24.385C30.2621 24.385 28.6765 25.9917 28.6765 27.9665C28.6765 29.9246 30.2451 31.5313 32.1886 31.5313C34.1321 31.5313 35.7007 29.9246 35.7007 27.9497C35.7007 25.9748 34.1322 24.3682 32.1886 24.3682Z" fill="black" />
    </svg>
  );
};

