import { Route, Routes } from "react-router";
import { useEffect } from "react";
import AOS from "aos";
import { YMInitializer } from 'react-yandex-metrika';

import Main from "./pages/Main";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "aos/dist/aos.css";
import "./App.css";

function App() {
  useEffect(() => {
    AOS.init({
      //offset: 200,
      duration: 500,
      easing: "ease-in-quad",
      delay: 100,
    });
  }, []);

  return (
    <div className="App">
      <YMInitializer
        accounts={[87449466]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
        }}
      />
      <header>
        <Header />
      </header>
      <main>
        <Routes>
          <Route index element={<Main />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
