import { getIn } from 'immutable';
import { nullAddress } from '../../constants';



const root = 'ref';

export const getParentId = (state) =>
  getIn(state, [root, 'parentId'], null);

export const getParentAddress = (state) =>
  getIn(state, [root, 'parentAddress'], nullAddress);
