import React from "react";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./Robots.css";

export const Robots: React.FC = () => {
  const { t } = useTranslation("robots");

  return (
    <section className="robots">
      <Container>
        <div data-aos="zoom-in-right">
          <Header as="h2" content={t("Header")} className="star-white" />
        </div>
        <Header.Subheader content={t("SubHeader")} />
        <Grid columns={3} textAlign="center" doubling className="bordered">
          <Grid.Column>
            <div data-aos="flip-left">
              <Image
                src="./images/robot-elon.png"
                alt="Robot Versions of Elon Musk"
                centered
              />
            </div>
            <div className="robots__name">{t("Robot1Header")}</div>
            {t("Robot1")}
          </Grid.Column>
          <Grid.Column>
            <div data-aos="flip-left">
              <Image
                src="./images/robot-spider.png"
                alt="A Spider-Robot"
                centered
              />
            </div>
            <div className="robots__name">{t("Robot2Header")}</div>
            {t("Robot2")}
          </Grid.Column>
          <Grid.Column>
            <div data-aos="flip-left">
              <Image
                src="./images/robot-military.png"
                alt="Military Robots"
                centered
              />
            </div>
            <div className="robots__name">{t("Robot3Header")}</div>
            {t("Robot3")}
          </Grid.Column>
          <Grid.Column>
            <div data-aos="flip-left">
              <Image
                src="./images/robot-knight.png"
                alt="Robot Knight"
                centered
              />
            </div>
            <div className="robots__name">{t("Robot4Header")}</div>
            {t("Robot4")}
          </Grid.Column>
          <Grid.Column>
            <div data-aos="flip-left">
              <Image
                src="./images/robot-scientists.png"
                alt="Robots-Scientists"
                centered
              />
            </div>
            <div className="robots__name">{t("Robot5Header")}</div>
            {t("Robot5")}
          </Grid.Column>
          <Grid.Column>
            <div data-aos="flip-left">
              <Image
                src="./images/robot-future.png"
                alt="Robots of the Future"
                centered
              />
            </div>
            <div className="robots__name">{t("Robot6Header")}</div>
            {t("Robot6")}
          </Grid.Column>
        </Grid>
        <Grid stackable className="smart">
          <Grid.Column width={4}>
            <Image
              src="./animation/planet.gif"
              alt="Robots of the Future"
              className="earth-img"
              centered
            />
          </Grid.Column>
          <Grid.Column width={12} textAlign="left" className="earth">
            <div className="header"> {t("12Movies")}</div>
            {t("Back")}
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
};
