import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { NetworkConnector } from './NetworkConnector';
import { ConnectorNames } from '../constants';
import { networkChainId, ALCHEMY_API, ALCHEMY_API_MAINNET, ALCHEMY_API_RINKEBY, pollingInterval } from '../configs';



export const network = new NetworkConnector({
  urls: {
    1: ALCHEMY_API_MAINNET,
    4: ALCHEMY_API_RINKEBY,
  },
  defaultChainId: networkChainId,
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any));
}

export const injected = new InjectedConnector({
  supportedChainIds: [1, 4],
});

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: ALCHEMY_API_MAINNET,
    4: ALCHEMY_API_RINKEBY,
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  //pollingInterval: pollingInterval,
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
}
