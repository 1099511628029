import React, { useState, useEffect, useCallback } from "react";
import { Button, Image, Icon, Form, } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import Modal, { useModalState } from "react-simple-modal-provider";
import { toast } from 'react-toastify';
import { ethers, utils } from 'ethers';
import Number from 'react-number-format';
import ym from 'react-yandex-metrika';
import { useEagerConnect, useActiveWeb3React } from '../hooks/index';
import useAuth from '../hooks/useAuth';
import MetamaskIcon from '../components/Svg/MetamaskIcon';
import WalletConnectIcon from '../components/Svg/WalletConnectIcon';
import { connectorLocalStorageKey, } from '../constants';
import {
  networkChainId, ALCHEMY_API, pollingInterval,
  airdropStartTime, presaleStartTime, saleStartTime,
  WL
} from '../configs';

import "./Modals.css";

import * as S from '../store/selectors';
import getNftContract from '../hooks/getNftContract';
/*
import getNftAddress from '../hooks/getNftAddress';
import getNftSmartContractUrl from '../hooks/getNftSmartContractUrl';
import { nullAddress } from '../constants';
import LinkExternal from '../components/Link/LinkExternal';
*/



const now = () => Math.trunc(Date.now() / 1000);
function pause(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const doTx = async (promiseTx) => {
  try {
    const tx = await promiseTx;
    if (!tx) return;
    toast.info('⏳ Pending Transaction...');
    await tx.wait(1);
    toast.success('🚀 Transaction Confirmed!');
  } catch (error) {
    console.error(error);
    toast.error('🔴 Transaction Failed.');
    if (typeof error === 'string' && error.includes('insufficient funds')) {
      toast.error('🔴 Insufficient funds');
    } else if (typeof error === 'object' && error.data.message.includes('insufficient funds')) {
      toast.error('🔴 Insufficient funds');
    } else {
      toast.error('🔴  Error');
    }
  }
}

const providerDefault = new ethers.providers.JsonRpcProvider(ALCHEMY_API);
providerDefault.pollingInterval = pollingInterval;



const MintModal = ({ children }) => {
  const [isOpen, setOpen] = useModalState();
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
      isOpen && ym('reachGoal', 'OpenMintModal');
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);


  const triedEager = useEagerConnect();
  const context = useActiveWeb3React();
  const { library, chainId, account, active } = context;


  const { login/*, logout,*/ } = useAuth();
  const onConnectToMetamask = () => {
    const connectorId = 'injected';
    login(connectorId);
    window.localStorage.setItem(connectorLocalStorageKey, connectorId);
  }
  const onConnectToWalletConnect = () => {
    const connectorId = 'walletconnect';
    login(connectorId);
    window.localStorage.setItem(connectorLocalStorageKey, connectorId);
  }
  const onSwitchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${networkChainId.toString(16)}`, }],
      });
    } catch (error) { }
  }


  const [time, setTime] = useState(0);
  useEffect(() => {
    setTime(now());
    const timer = setInterval(() => {
      setTime(now());
    }, 1000);
    return () => clearInterval(timer);
  });


  const maxSupply = 5000;
  const [totalSupply, setTotalSupply] = useState(0);
  const [isSoldOut, setSoldOut] = useState(false);


  const [isAirdropOpen, setAirdropOpen] = useState(false);
  const [airdropMintsTotal, setAirdropMintsTotal] = useState(0);
  const [isPresaleOpen, setPresaleOpen] = useState(false);
  const [isSaleOpen, setSaleOpen] = useState(false);
  const [step, setStep] = useState('Сoming soon');
  useEffect(() => {
    if (
      time >= airdropStartTime
      && time < presaleStartTime
      && !isAirdropOpen && !isSoldOut
      && airdropMintsTotal >= 100
    ) {
      setAirdropOpen(false);
      toast.success(<b style={{ color: 'rgba(0,0,0,.87)', }}>🚀 Airdrop is Done!</b>, { autoClose: 10000, });
    }

    if (
      time >= airdropStartTime
      && time < presaleStartTime
      && !isAirdropOpen && !isSoldOut
      && airdropMintsTotal < 100
    ) {
      /*setStep('Airdrop is Live');
      setAirdropOpen(true);
      setPresaleOpen(false);
      setSaleOpen(false);
      toast.success(<b style={{ color: 'rgba(0,0,0,.87)', }}>🚀 Airdrop is Live!</b>, { autoClose: 10000, });*/
    } else if (
      time >= presaleStartTime
      && time < saleStartTime
      && !isPresaleOpen
      && !isSoldOut
    ) {
      setStep('Presale is Live');
      setAirdropOpen(false);
      setPresaleOpen(true);
      setSaleOpen(false);
      //toast.success(<b style={{ color: 'rgba(0,0,0,.87)', }}>🚀 Presale is Live!</b>, { autoClose: 10000, });
    } else if (time >= saleStartTime && !isSaleOpen && !isSoldOut) {
      setStep('Presale is Live');
      setAirdropOpen(false);
      setPresaleOpen(false);
      setSaleOpen(true);
      toast.success(<b style={{ color: 'rgba(0,0,0,.87)', }}>🚀 Sale is Live!</b>, { autoClose: 10000, });
    }
  }, [time, airdropMintsTotal, isSoldOut]);


  useEffect(() => {
    if (
      totalSupply > 0
      && !isSoldOut
      && (isSaleOpen)
    ) {
      toast.info(
        <b style={{ color: 'rgba(0,0,0,.87)', }}>
          🚀 Minted:{" "}
          <Number value={totalSupply} displayType='text' thousandSeparator style={{ color: '#e62e2d', fontSize: '1.33rem' }} /> /{" "}
          <Number value={maxSupply} displayType='text' thousandSeparator />
        </b>
      );
    }
    if (totalSupply >= maxSupply) {
      setSoldOut(true);
      toast.success(<b style={{ color: 'rgba(0,0,0,.87)', }}>🚀 Sold Out!</b>, { autoClose: 10000, });
    }
  }, [totalSupply]);


  const [isAirdropWL, setAirdropWL] = useState(false);
  const [airdropMinted, setAirdropMinted] = useState(0);
  const [isPresaleWL, setPresaleWL] = useState(false);

  useEffect(() => {
    isAirdropWL && toast.success(<b style={{ color: 'rgba(0,0,0,.87)', }}>🚀 You are Airdrop whitelisted!</b>, { autoClose: 10000, });
  }, [isAirdropWL]);
  useEffect(() => {
    isPresaleWL && toast.success(<b style={{ color: 'rgba(0,0,0,.87)', }}>🚀 You are Presale whitelisted!</b>, { autoClose: 10000, });
  }, [isPresaleWL]);

  const [blockNumber, setBlockNumber] = useState(0);
  const [isUpdating, setUpdating] = useState(false);
  const update = async (forced) => {
    if (!forced && isUpdating) return;
    setUpdating(true);
    try {
      const contract = getNftContract(
        networkChainId,
        library ? library : providerDefault,
        false
      );
      let ts = await contract.totalSupply();
      ts = ts.toNumber();
      if (ts > totalSupply) {
        setTotalSupply(ts);
      }
      const mt = await contract.airdropMintsTotal();
      setAirdropMintsTotal(mt.toNumber());

      if (account) {
        /*if (isAirdropOpen) {
          const isWL = await contract.airdropWhitelist(account);
          setAirdropWL(isWL);
          if (isWL) {
            let minted = await contract.airdropMints(account);
            minted = minted.toNumber();
            setAirdropMinted(minted);
          }
        }*/
        if (isPresaleOpen) {
          //const isWL = await contract.presaleWhitelist(account);
          //const isWL = WL.includes(account.toLowerCase());
          //setPresaleWL(isWL);
          setPresaleWL(true);
        }
      }
    } catch (error) { }
    setUpdating(false);
  }
  useEffect(update, [blockNumber]);

  const updateBlockNumber = async (currentBlockNumber) => {
    if (currentBlockNumber > blockNumber) {
      setBlockNumber(currentBlockNumber);
    }
  }
  useEffect(() => {
    if (library) {
      providerDefault.removeAllListeners('block');
      library.on('block', updateBlockNumber);
      update(true);
    } else {
      providerDefault.on('block', updateBlockNumber);
    }
  }, [library]);


  const [balance, setBalance] = useState('0');
  const [balanceEth, setBalanceEth] = useState('0');
  useEffect(() => {
    if (library && account) {
      library.getBalance(account).then((balanceEthRaw) => {
        setBalanceEth(utils.formatEther(balanceEthRaw));
      }).catch((error) => null);

      const contract = getNftContract(
        networkChainId,
        library ? library : providerDefault,
        false
      );
      if (contract) {
        contract.balanceOf(account).then((balance) => {
          setBalance(balance.toNumber());
        }).catch((error) => null);
      }
    }
  }, [blockNumber, library, account]);


  const [count, setCount] = useState(1);
  const onChange = (e, { name, value }) => {
    switch (name) {
      case 'count': {
        if (isAirdropOpen) {
          if (value > 1) {
            setCount(1);
            return;
          }
        }
        setCount(value);
        return;
      }
      default: return;
    }
  }


  const pricePresale = '0.2';
  const priceSale = '0.5';
  const [priceEth, setPriceEth] = useState(priceSale);
  useEffect(() => {
    if (isPresaleOpen) setPriceEth(pricePresale);
    else if (isSaleOpen) setPriceEth(priceSale);
  }, [isAirdropOpen, isPresaleOpen, isSaleOpen]);

  const [totalEth, setTotalEth] = useState('0.00');
  useEffect(() => {
    setTotalEth((count * priceEth).toFixed(2));
  }, [count, priceEth]);


  const [mintDisabled, setMintDisabled] = useState(false);
  const referrer = useSelector(S.ref.getParentAddress);
  const onMint = async () => {
    if (mintDisabled) return;

    if (parseFloat(totalEth) >= parseFloat(balanceEth)) {
      toast.error('🔴 Insufficient funds.');
      //return;
    }
    const contract = getNftContract(
      networkChainId,
      library ? library : providerDefault,
      true
    );
    if (isAirdropOpen) {
      await doTx(contract.mintAirdrop());
      update(true);
      ym('reachGoal', 'MintDone');
      return;
    }

    if (count === 0) return;
    let value = utils.parseEther(totalEth);

    let gasPrice = await library.getGasPrice();
    gasPrice = gasPrice.mul(120).div(100);

    let gasLimit = await contract.estimateGas.mintSale(count, referrer, { value, gasPrice, });
    gasLimit = gasLimit.mul(120).div(100);

    if (isPresaleOpen) {
      await doTx(contract.mintSale(count, referrer, { value, gasPrice, gasLimit, }));
      ym('reachGoal', 'MintDone');
      update(true);
      return;
    }

    if (isSaleOpen) {
      await doTx(contract.mintSale(count, referrer, { value, gasPrice, gasLimit, }));
      ym('reachGoal', 'MintDone');
      update(true);
      return;
    }
  }

  return (
    <Modal
      id={"MintModal"}
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      draggable={false}
      duration={300}
      radius={10}
    >
      {(triedEager && active && account)
        ? chainId === networkChainId ? <div className="modal">
          <div className="modal-title">
            <div>
              <h1>{step}</h1>
              <div className="tab" style={{ marginTop: '-10px' }}>
                <b style={{ color: '#fff', }}>
                  Minted:{" "}
                  <Number value={totalSupply} displayType='text' thousandSeparator style={{ color: '#e62e2d', fontSize: '1.33rem' }} /> /{" "}
                  <Number value={maxSupply} displayType='text' thousandSeparator />
                </b>
              </div>

              {isAirdropOpen && isAirdropWL && airdropMinted < 0 && airdropMintsTotal < 100 && <div>
                <Form>
                  <Button type='submit' fluid style={{ padding: '7px', }} onClick={onMint}
                    disabled={!isAirdropWL}
                  >MINT</Button>
                </Form>
              </div>}

              {isPresaleOpen && isPresaleWL && <div>
                <Form>
                  <Form.Input required
                    id='count' name='count' label='Amount'
                    type='number' min='0' step='1'
                    value={count} onChange={onChange}
                    style={{ textAlign: 'center' }}
                  />
                  <span><b>Total: {count} x {priceEth} ETH = {totalEth} ETH</b></span>
                  <br /><span>Excluding gas fee</span>
                  <br />
                  <br />
                  <Button type='submit' fluid style={{ padding: '7px', }} onClick={onMint}>
                    MINT
                  </Button>
                </Form>
              </div>}

              {isSaleOpen && <div>
                <Form>
                  <Form.Input required
                    id='count' name='count' label='Amount'
                    type='number' min='0' step='1'
                    value={count} onChange={onChange}
                    style={{ textAlign: 'center' }}
                  />
                  <span><b>Total: {count} x {priceEth} ETH = {totalEth} ETH</b></span>
                  <br /><span>Excluding gas fee</span>
                  <br />
                  <br />
                  <Button type='submit' fluid style={{ padding: '7px', }} onClick={onMint}>
                    MINT
                  </Button>
                </Form>
              </div>}

              <hr />
              <b style={{ fontSize: '1rem' }}>
                <div>{account}</div>
                <div style={{ textAlign: 'left' }}>
                  Balance, ETH: <Number value={balanceEth} displayType='text' suffix=" ETH" thousandSeparator fixedDecimalScale decimalScale={4} />
                </div>
                <div style={{ textAlign: 'left' }}>
                  Balance, ROBO: <Number value={balance} displayType='text' suffix=" ROBO" thousandSeparator fixedDecimalScale decimalScale={0} />
                </div>
              </b>
            </div>
          </div>
        </div>

          : <div className="modal">
            <div className="modal-title">
              <div><h1>Wrong Network</h1></div>
              <hr />
            </div>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <p><b>Switch Network to Ethereum Mainnet</b></p>
              <Button fluid style={{ padding: '7px', }} onClick={onSwitchNetwork}>
                Switch Network
              </Button>
            </div>
          </div>
        : <div className="modal">
          <div className="modal-title">
            <div><h1>Connect Wallet</h1></div>
            <hr />
          </div>
          <div style={{ marginTop: '10px' }}>
            <div style={{ padding: '10px' }}>
              <Button fluid style={{ padding: '7px', }} onClick={onConnectToMetamask}>
                <span><MetamaskIcon /></span> Metamask
              </Button>
            </div>
            <div style={{ padding: '10px' }}>
              <Button fluid style={{ padding: '7px', }} onClick={onConnectToWalletConnect}>
                <span><WalletConnectIcon /></span> Wallet Connect
              </Button>
            </div>
          </div>
        </div>
      }
    </Modal>
  );
};

export default MintModal;
