import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import rehydrate from './rehydrate/reducers';
import ref from './ref/reducers';



export const createRootReducer = () => {
  const rootReducer = combineReducers({
    rehydrate,
    ref: persistReducer({ key: 'ref', storage: storage, stateReconciler: autoMergeLevel1, }, ref),
    //ref,
  });

  return rootReducer;
};

export default createRootReducer;
