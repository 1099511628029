import React from "react";
import { Container, Grid, Header, Image, Button } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import { useModal } from "react-simple-modal-provider";
import ym from 'react-yandex-metrika';
import "./Why.css";

export const Why: React.FC = () => {
  const { t } = useTranslation("why");
  const { open: openMintModal } = useModal("MintModal");
  function openMintModal2() {
    window.open('https://discord.gg/RoboToken', '_blank');
    ym('reachGoal', 'OpenDiscord');
  }

  return (
    <section className="why">
      <Container>
        <div className="view-mobile">
          <div data-aos="fade-up-right">
            <Header as="h2" content={t("Header")} className="star" />
          </div>
        </div>
        <Grid columns={2} textAlign="center" celled stackable>
          <Grid.Row only="computer tablet">
            <div data-aos="fade-up-right">
              <Header as="h2" content={t("Header")} className="star" />
            </div>
          </Grid.Row>
          <Grid.Row className="why__lines"></Grid.Row>
          <Grid.Row className="why__content aligned ">
            <Grid
              textAlign="center"
              columns={"equal"}
              verticalAlign="middle"
              stackable
            >
              <Grid.Column textAlign="left">
                <Trans t={t} i18nKey="Children">
                  1<strong>Mask</strong>
                </Trans>
              </Grid.Column>
              <Grid.Column>
                <div data-aos="zoom-in">
                  <Image
                    src="./images/elon-musk.png"
                    alt="Robot Versions of Elon Musk"
                    centered
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7} verticalAlign="middle">
              <div data-aos="zoom-in">
                <Image
                  src="./images/banknote-small.png"
                  alt="Robot Versions of Elon Musk"
                  centered
                />
              </div>
            </Grid.Column>
            <Grid.Column width={9} textAlign="left" className="why__first">
              <div className="header">{t("First")}</div>
              <Trans t={t} i18nKey="WeIntend">
                1<b>2</b>
              </Trans>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns="equal" stackable className="why__among">
          <Grid.Column textAlign="left" width={10}>
            <div className="header"> {t("Among")}</div>
            {t("OverTime")}
          </Grid.Column>
          <Grid.Column textAlign="right" width={6}>
            <Button onClick={openMintModal2}>{t("Mint")}</Button>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
};
