import React from "react";
import { Container, Grid } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import Social from "./Social";

const Footer: React.FC = () => {
  const { t } = useTranslation("layout");

  return (
    <Container>
      <div className="footer">
        <Grid columns="equal" stackable>
          <Grid.Column textAlign="left">
            <div className="musk">“Musk’s Children”</div>
            <div className="community">
              {t("Community")}
              <span className="from">
                <Trans t={t} i18nKey="From">
                  1<b>2</b>
                </Trans>
              </span>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <div className="space-bt v-center view-desktop-1">
              <div className="copyright ">{t("AllRights")}</div>
              <div className="social ">
                <Social />
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <div id="my_player"
          className="my_player" data-player="energy" data-skin="blue" data-width='200' data-autoplay='1'
          data-volume='70' data-streamurl="https://myradio24.org/33338"
        ></div>
        <div className="social view-mobile-1">
          <Social />
          <div className="copyright ">
            <br />
            {t("AllRights")}
          </div>
        </div>
      </div>
    </Container >
  );
};

export default Footer;
