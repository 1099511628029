import React from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import "./Miles.css";

export const Miles: React.FC = () => {
  const { t } = useTranslation("miles");

  return (
    <section className="miles">
      <Container>
        <Grid stackable textAlign="left" columns={"equal"}>
          <Grid.Column>
            <div data-aos="flip-left">
              <Header as="h2">
                <Trans t={t} i18nKey="Header">
                  1<strong>2</strong>
                </Trans>
              </Header>
            </div>
            {t("CodeName")}
            <div className="release">
              <Trans t={t} i18nKey="Release">
                1<b>2</b>
              </Trans>
            </div>
          </Grid.Column>
          <Grid.Column className="blockquote-col" verticalAlign="bottom">
            <div className="line line-1" data-aos="fade-left" />
            <blockquote>{t("Blockquote")}</blockquote>
            <div className="line line-2" data-aos="fade-left" />
          </Grid.Column>
        </Grid>
        <Grid stackable textAlign="left" columns={2} className="tabs">
          <Grid.Column>
            <div data-aos="fade-right">
              <div className="tab">V1</div>
              <p>Q1 2022</p>
              {t("Q1")}
              <ul>
                <li>{t("Q1Item1")}</li>
                <li>{t("Q1Item2")}</li>
                <li>{t("Q1Item3")}</li>
                <li>{t("Q1Item4")}</li>
                <li>{t("Q1Item5")}</li>
                <li>{t("Q1Item6")}</li>
                <li>{t("Q1Item7")}</li>
                <li>{t("Q1Item8")}</li>
              </ul>
              {t("Q1Summary")}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div data-aos="fade-left" data-aos-offset="500">
              <div className="tab">V2</div>
              <p>Q2 2022</p>
              {t("Q2")}
              <ul>
                <li>{t("Q2Item1")}</li>
                <li>{t("Q2Item2")}</li>
                <li>{t("Q2Item3")}</li>
                <li>{t("Q2Item4")}</li>
              </ul>
              {t("Q2Summary")}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div data-aos="fade-right">
              <div className="tab">V3</div>
              <p>Q3 2022</p>
              {t("Q3")}
              <ul>
                <li>{t("Q3Item1")}</li>
                <li>{t("Q3Item2")}</li>
                <li>{t("Q3Item3")}</li>
                <li>{t("Q3Item4")}</li>
              </ul>
              {t("Q3Summary")}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div data-aos="fade-left" data-aos-offset="500">
              <div className="tab">V4</div>
              <p>Q4 2022</p>
              {t("Q4")}
              <ul>
                <li>{t("Q4Item1")}</li>
                <li>{t("Q4Item2")}</li>
                <li>{t("Q4Item3")}</li>
                <li>{t("Q4Item4")}</li>
                <li>{t("Q4Item5")}</li>
                <li>{t("Q4Item6")}</li>
                <li>{t("Q4Item7")}</li>
              </ul>
              {t("Q4Summary")}
            </div>
          </Grid.Column>
        </Grid>
        <Grid stackable textAlign="left" celled className="colored">
          <Grid.Column width={7} className="col-dark">
            <div className="looking">{t("Looking")}</div>
            <div className="weIntend">{t("WeIntend")}</div>
            <div className="everything">{t("Everything")}</div>
          </Grid.Column>
          <Grid.Column width={9} className="col-white">
            <div className="up">{t("Believe")}</div>
            <ul>
              <li>{t("BelieveItem1")}</li>
              <li>{t("BelieveItem2")}</li>
              <li>{t("BelieveItem3")}</li>
              <li>{t("BelieveItem4")}</li>
            </ul>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
};
