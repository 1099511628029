import React from "react";
import { Container, Grid, Header, Button } from "semantic-ui-react";
import { useTranslation, Trans } from "react-i18next";
import { useModal } from "react-simple-modal-provider";
import { Observe } from 'react-observe-component';
import ym from 'react-yandex-metrika';
import "./Road.css";

export const Road: React.FC = () => {
  const { t } = useTranslation("road");
  const { open: openMintModal } = useModal("MintModal");
  function openMintModal2() {
    window.open('https://discord.gg/RoboToken', '_blank');
    ym('reachGoal', 'OpenDiscord');
  }

  const isIntersecting = (entry: any) => {
    if (entry.isIntersecting === true) {
      ym('reachGoal', 'ViewContent');
    }
  }

  return (
    <Observe
      as='section'
      id='road'
      className='road'
      isIntersecting={isIntersecting}
      options={{ threshold: 0 }}
    >
      <Container>
        <div data-aos="flip-down">
          <Header as="h2" content={t("Header")} className="star" />
        </div>
        <Header.Subheader>{t("SubHeader")}</Header.Subheader>

        <Grid columns="2" stackable verticalAlign="middle">
          <Grid.Column className="lines" width={9} textAlign="left">
            <div data-aos="zoom-in">
              <Trans t={t} i18nKey="DoYou">
                1<strong>2</strong>
              </Trans>
            </div>
          </Grid.Column>
          <Grid.Column width={7}>
            <div className="road__mint">
              <div className="line line-1" data-aos="fade-left" />
              <div className="line line-2" data-aos="fade-left" />
              <Button onClick={openMintModal2}>{t("Mint")}</Button>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </Observe>
  );
};
