import React from "react";
import { Container, Grid, Header, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./Countdown.css";

export const Countdown: React.FC = () => {
  const { t } = useTranslation("countdown");

  return (
    <section className="countdown">
      <Container>
        <div data-aos="zoom-out-right">
          <Header as="h2" content={t("Header")} className="lined star" />
        </div>
        <div className="countdown_launch">
          <div data-aos="flip-left">
            <div className="staticFlip">5</div>
            <div className="step">{t("Step5Header")}</div>
            {t("Step5")}
          </div>
          <div data-aos="flip-left" data-aos-delay="300">
            <div className="staticFlip">4</div>
            <div className="step">{t("Step4Header")}</div>
            {t("Step4")}
          </div>
          <div data-aos="flip-left" data-aos-delay="600">
            <div className="staticFlip">3</div>
            <div className="step">{t("Step3Header")}</div>
            {t("Step3")}
          </div>
          <div data-aos="flip-left" data-aos-delay="900">
            <div className="staticFlip">2</div>
            <div className="step">{t("Step2Header")}</div>
            {t("Step2")}
          </div>
          <div data-aos="flip-left" data-aos-delay="1200">
            <div className="staticFlip">1</div>
            <div className="step">{t("Step1Header")}</div>
            {t("Step1")}
          </div>
          <div data-aos="flip-left" data-aos-delay="1500">
            <div className="staticFlipText">Launch!</div>
            <Image
              src="./images/rocket.png"
              alt="star"
              className="rocket"
              data-aos="flip-left"
              data-aos-delay="1800"
            />
          </div>
        </div>
        <div className="rSpace">{t("RSpace")}</div>
        <Grid stackable textAlign="left" celled>
          <Grid.Column width={7} className="col-dark">
            <div> {t("HowTo")}</div>
            {t("Access")}
          </Grid.Column>
          <Grid.Column width={9} className="col-white">
            <div>{t("OurPartners")}</div>
            {t("Project")}
          </Grid.Column>
        </Grid>
      </Container>
    </section>
  );
};
