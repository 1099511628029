import { setIn } from 'immutable';
import { nullAddress } from '../../constants';
import * as AT from './actionTypes';



const INITIAL_STATE = {
  //refId: null,
  parentId: null,
  parentAddress: nullAddress,
};


function refReducer(state = INITIAL_STATE, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return INITIAL_STATE;
    }
    case AT.SET_PARENT_ID: {
      return setIn(state, ['parentId'], payload);
    }
    case AT.SET_PARENT_ADDRESS: {
      return setIn(state, ['parentAddress'], payload);
    }

    default:
      return state;
  }
}

export default refReducer;
