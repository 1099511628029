import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import createEnhancers, { sagaMiddleware } from './enhancers';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';
import createApi from '../api';
import { apiKey, rootDomain, apiDomain } from '../configs';
//import * as A from './actions';



const persistConfig = {
  key: 'store',
  storage: storage,
  whitelist: [],
  stateReconciler: autoMergeLevel1,
};

const options = {
  domains: { root: rootDomain, api: apiDomain, },
};
const apiOptions = { apiKey, options, };
export let api = createApi({ ...apiOptions, });


const initialState = {};

export const configureStore = (preloadedState = {}) => {
  const store = createStore(
    persistReducer(persistConfig, createRootReducer()),
    Object.assign({}, initialState, preloadedState),
    createEnhancers(),
  );
  const persistor = persistStore(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const createNewRootReducer = require('./rootReducer');
      store.replaceReducer(persistReducer(persistConfig, createNewRootReducer()));
    });
  }

  /*api = createApi({
    ...apiOptions,
    dispatch: store.dispatch,
  });*/
  sagaMiddleware.run(rootSaga, { api, options });

  return { store, persistor };
}

export default configureStore;
