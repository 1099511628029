import * as AT from './actionTypes';



export const clear = () => ({
  type: AT.CLEAR,
});

export const update = () => ({
  type: AT.UPDATE,
});

export const setParentId = (data) => ({
  type: AT.SET_PARENT_ID,
  payload: data,
});

export const setParentAddress = (data) => ({
  type: AT.SET_PARENT_ADDRESS,
  payload: data,
});

