export default function Icon() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="filling" d="M26 31.332C28.9448 31.332 31.332 28.9448 31.332 26C31.332 23.0552 28.9448 20.668 26 20.668C23.0552 20.668 20.668 23.0552 20.668 26C20.668 28.9448 23.0552 31.332 26 31.332Z" fill="black" />
      <path id="filling" d="M26 0.609375C11.9772 0.609375 0.609375 11.9772 0.609375 26C0.609375 40.0228 11.9772 51.3906 26 51.3906C40.0228 51.3906 51.3906 40.0228 51.3906 26C51.3906 11.9772 40.0228 0.609375 26 0.609375ZM41.6871 32.4533C41.5653 34.8672 40.8869 37.2476 39.1278 38.9881C37.3515 40.7451 34.9598 41.3998 32.5226 41.5205H19.4775C17.04 41.3998 14.6486 40.7456 12.8723 38.9881C11.1131 37.2476 10.4348 34.8672 10.313 32.4533V19.5467C10.4348 17.1328 11.1132 14.7524 12.8723 13.0118C14.6486 11.2548 17.0403 10.6001 19.4775 10.4795H32.5225C34.96 10.6001 37.3514 11.2544 39.1277 13.0118C40.8869 14.7524 41.5652 17.1328 41.687 19.5467L41.6871 32.4533Z" fill="black" />
      <path id="filling" d="M32.3577 13.4203C29.1802 13.3331 22.8204 13.3331 19.6429 13.4203C17.9894 13.4657 16.1148 13.8773 14.9367 15.1357C13.7125 16.4437 13.2498 18.024 13.2029 19.7919C13.1204 22.8948 13.2029 32.2081 13.2029 32.2081C13.2566 33.9758 13.7125 35.5563 14.9367 36.8644C16.1148 38.1231 17.9894 38.5344 19.6429 38.5798C22.8204 38.6669 29.1802 38.6669 32.3577 38.5798C34.0112 38.5344 35.8858 38.1227 37.0639 36.8644C38.2881 35.5563 38.7508 33.976 38.7977 32.2081V19.7919C38.7508 18.024 38.2881 16.4437 37.0639 15.1357C35.8854 13.8769 34.0108 13.4657 32.3577 13.4203ZM25.9999 34.2647C24.3653 34.2647 22.7674 33.78 21.4083 32.8718C20.0492 31.9637 18.9899 30.6729 18.3644 29.1628C17.7388 27.6526 17.5752 25.9908 17.894 24.3877C18.2129 22.7845 19.0001 21.3119 20.1559 20.156C21.3117 19.0002 22.7844 18.2131 24.3875 17.8942C25.9907 17.5753 27.6525 17.7389 29.1626 18.3645C30.6728 18.99 31.9636 20.0493 32.8717 21.4084C33.7798 22.7675 34.2645 24.3654 34.2645 26C34.2645 28.1919 33.3938 30.2941 31.8439 31.844C30.294 33.3939 28.1918 34.2647 25.9999 34.2647ZM34.2997 19.4413C33.9728 19.4412 33.6532 19.3442 33.3815 19.1625C33.1097 18.9809 32.8979 18.7227 32.7729 18.4206C32.6478 18.1186 32.6151 17.7863 32.679 17.4657C32.7428 17.145 32.9003 16.8506 33.1314 16.6194C33.3626 16.3883 33.6571 16.2309 33.9778 16.1672C34.2984 16.1034 34.6307 16.1362 34.9327 16.2613C35.2347 16.3864 35.4929 16.5983 35.6745 16.8701C35.8561 17.1419 35.953 17.4615 35.953 17.7884C35.953 18.0055 35.9102 18.2205 35.8272 18.421C35.7441 18.6216 35.6223 18.8038 35.4687 18.9573C35.3152 19.1108 35.1329 19.2326 34.9324 19.3156C34.7318 19.3986 34.5168 19.4414 34.2997 19.4413Z" fill="black" />
    </svg>
  );
};

