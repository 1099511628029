import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enLayout from "./locales/en/layout.json";
import enCollection from "./locales/en/collection.json";
import enExplore from "./locales/en/explore.json";
import enWhy from "./locales/en/why.json";
import enRobots from "./locales/en/robots.json";
import enAbout from "./locales/en/about.json";
import enCountdown from "./locales/en/countdown.json";
import enMiles from "./locales/en/miles.json";
import enRoad from "./locales/en/road.json";

/*import ruLayout from "./locales/en/layout.json";
import ruCollection from "./locales/ru/collection.json";
import ruExplore from "./locales/ru/explore.json";
import ruWhy from "./locales/ru/why.json";
import ruRobots from "./locales/ru/robots.json";
import ruAbout from "./locales/ru/about.json";
import ruCountdown from "./locales/ru/countdown.json";
import ruMiles from "./locales/ru/miles.json";
import ruRoad from "./locales/ru/road.json";*/

export const resources = {
  en: {
    layout: enLayout,
    collection: enCollection,
    explore: enExplore,
    why: enWhy,
    robots: enRobots,
    about: enAbout,
    countdown: enCountdown,
    miles: enMiles,
    road: enRoad,
  },
  /*ru: {
    layout: ruLayout,
    collection: ruCollection,
    explore: ruExplore,
    why: ruWhy,
    robots: ruRobots,
    about: ruAbout,
    countdown: ruCountdown,
    miles: ruMiles,
    road: ruRoad,
  },*/
} as const;

const detection = {
  order: ["querystring", "localStorage", "navigator"],
  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupLocalStorage: "lng",
  lookupCookie: "lng",
  //lookupSessionStorage: 'lng',
  //lookupFromPathIndex: 0,
  //lookupFromSubdomainIndex: 0,
  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection,
    supportedLngs: ["en"],//, "ru"],
    ns: [""],
    //lng: isProduction ? undefined : 'en',
    fallbackLng: "en",
    fallbackNS: "translation",
    resources,
    debug: false, //!isProduction,
    react: {
      useSuspense: true,
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
