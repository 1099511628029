import { isProduction } from './constants';


export const appVersion = '1.0.0';
export const appName = 'RoboToken';

export const ALCHEMY_API_MAINNET = 'https://eth-mainnet.alchemyapi.io/v2/WpgJn7QSGBjMHOQvtyachgA2Ps4Er27d';
export const ALCHEMY_API_RINKEBY = 'https://eth-rinkeby.alchemyapi.io/v2/xaM_lEWLTQyTTJIRzz2i0RT6stYylsJI';
export const ALCHEMY_API = ALCHEMY_API_MAINNET;
export const networkChainId = 1; // TODO // 1 - mainnet, 4 - rinkeby
export const pollingInterval = 15000;

export const apiKey = '7e1ef597-ba8d-4727-bf5d-c6eb19ecf549';
export const rootDomain = isProduction ? 'https://robotoken.space' : `http://${window.location.hostname}:3000`;
export const apiDomain = isProduction ? 'https://api.robotoken.space' : `http://${window.location.hostname}:3100`;

export const nftMainnetAddress = '0xb1cfdce0762317fc0941fe32f68260e2760d022f';
export const nftRinkebyAddress = '0xF0E4e29A0Fb10Ed98e19e95C9FFb8743d2a9fc76';

export const airdropStartTime = 1645358400; // 1645358400= 2022-02-20T12:00:00.000Z
export const presaleStartTime = 1663614000; // 1663614000= 2022-09-19T19:00:00.000Z
export const saleStartTime = 1663621200; // 1663621200= 2022-09-19T21:00:00.000Z


export const WL = [
];